import { SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { SocialIcons } from '@/SocialIcons';
import { ReactComponent as Appointment } from '@a/icons/appointment.svg';
import { ReactComponent as Shop } from '@a/icons/shop.svg';
import { ReactComponent as LogoTagline } from '@a/images/layout/logo-tagline.svg';
import { ReactComponent as LogoText } from '@a/images/layout/logo-text.svg';
import logoVector from '@a/images/layout/logo-vector.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment, useEffect, useRef, useState } from 'react';
import { NavMenu } from './NavMenu';
import { flexCenter, px } from './styles/classes';
// eslint-disable-next-line
import { useLocation } from '@reach/router';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { getNavLink } from './NavLinks';

const Navbar = styled.nav<{ homepage: boolean; scrolled: boolean }>`
    ${px};
    ${flexCenter};
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    background-color: ${({ theme, homepage }) => (homepage ? '' : theme.colors.pink3)};
    z-index: 90;
    position: sticky;
    top: 0;
    width: 100%;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.gray1};
            border-bottom: 1px solid ${theme.colors.gray4};
        `};
`;

const HomeLogo = styled(CLink)<{ homepage: string; scrolled: string }>`
    ${flexCenter};
    margin-right: auto;
    max-width: 246px;

    > div {
        ${flexCenter};
        flex-direction: column;

        svg {
            path {
                transition: fill 0.3s ease-in-out;
            }

            :first-of-type {
                margin-bottom: 10px;

                path {
                    fill: ${({ homepage, theme, scrolled }) =>
                        scrolled === 'true'
                            ? theme.colors.gray3
                            : homepage === 'true'
                            ? theme.colors.gray1
                            : theme.colors.gray3};
                }
            }
        }
    }

    :hover {
        > div svg {
            :first-of-type {
                path {
                    fill: ${({ theme, homepage }) =>
                        homepage === 'true' ? theme.colors.green2 : theme.colors.green2};
                }
            }

            :last-of-type {
                path {
                    fill: ${({ theme, homepage, scrolled }) =>
                        scrolled === 'true'
                            ? theme.colors.gray3
                            : homepage === 'true'
                            ? theme.colors.gray1
                            : theme.colors.gray3};
                }
            }
        }
    }

    :focus-visible {
        svg {
            :first-of-type {
                path {
                    fill: ${({ theme, homepage }) =>
                        homepage === 'true' ? theme.colors.green2 : theme.colors.green2};
                }
            }

            :last-of-type {
                path {
                    fill: ${({ theme, homepage, scrolled }) =>
                        scrolled === 'true'
                            ? theme.colors.gray3
                            : homepage === 'true'
                            ? theme.colors.gray1
                            : theme.colors.gray3};
                }
            }
        }
    }

    > img {
        margin-right: 18px;
        width: 54.4px;
        height: 48px;
    }

    @media (max-width: 340px) {
        > div {
            display: none;
        }
    }

    @media (min-width: 1280px) {
        margin: 0 auto;
        flex-direction: column;

        > img {
            margin-right: 0;
            margin-bottom: 23px;
            width: 68px;
            height: 60px;
        }
    }
`;

const AppointmentBtn = styled(SecondaryBtn)`
    > span {
        margin-left: 18px;
    }

    > svg {
        path {
            transition: fill 0.3s ease-in-out;

            ${({ white, theme }) =>
                !white &&
                css`
                    fill: ${theme.colors.green2};
                `};
        }
    }

    @media (max-width: 400px) {
        display: none;
    }

    @media (max-width: 767px) {
        padding: 0;
        box-shadow: none;

        > span {
            display: none;
        }
    }
`;

const ShopBtn = styled(SecondaryBtn)`
    margin-left: 32px;
    padding: 0;
    box-shadow: none;
    flex-direction: column;

    > span {
        margin-top: 3px;
        font-weight: 400;
        line-height: 151%;
        color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.green2)};
        transition: color 0.3s ease-in-out;
    }

    > svg {
        path {
            transition: fill 0.3s ease-in-out;

            ${({ white, theme }) =>
                !white &&
                css`
                    fill: ${theme.colors.green2};
                `};
        }
    }

    :hover {
        background-color: transparent !important;

        > svg {
            path {
                fill: ${({ theme }) => theme.colors.green1};
            }
        }

        > span {
            color: ${({ theme }) => theme.colors.green1};
        }
    }

    :focus-visible {
        background-color: transparent !important;

        > svg {
            path {
                fill: ${({ theme }) => theme.colors.green1};
            }
        }

        > span {
            color: ${({ theme }) => theme.colors.green1};
        }
    }

    @media (max-width: 450px) {
        display: none;
    }

    @media (max-width: 767px) {
        > span {
            display: none;
        }
    }
`;

const iconStyles = css`
    padding-right: 235px;
    box-sizing: content-box;

    @media (max-width: 1279px) {
        display: none;
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
    opacity: 0;
`;

export const Nav = () => {
    const [closeDialogs, setCloseDialogs] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const closeAllDialogs = () => setCloseDialogs(open => !open);

    const location = useLocation();

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    const homepage = location.pathname === '/';

    return (
        <Fragment>
            <Navbar homepage={homepage} scrolled={scrolled}>
                <SocialIcons css={iconStyles} dark={scrolled || !homepage} />
                <HomeLogo
                    to="/"
                    aria-label="beauty religion homepage"
                    homepage={homepage.toString()}
                    scrolled={scrolled.toString()}
                >
                    <img src={logoVector} alt="beauty religion" width={68} height={60} />
                    <div>
                        <LogoText />
                        <LogoTagline />
                    </div>
                </HomeLogo>

                <AppointmentBtn
                    white={scrolled ? '' : homepage ? 'true' : ''}
                    as={CLink}
                    to={getNavLink('appointment')}
                >
                    <Appointment /> <span>Book Appointment</span>
                </AppointmentBtn>

                <ShopBtn
                    white={scrolled ? '' : homepage ? 'true' : ''}
                    as={CLink}
                    to={getNavLink('shop')}
                >
                    <Shop /> <span>Shop</span>
                </ShopBtn>

                <NavMenu
                    dialogClosed={closeDialogs}
                    closeAllDialogs={closeAllDialogs}
                    scrolled={scrolled}
                />
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
